import React from 'react'
import queryString from 'query-string'
import _ from 'lodash'
import { Link } from 'gatsby'
import styled from 'styled-components'

const SimpleFilterStyles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  padding: 0;
  margin: 0;
  gap: 0.8rem;
  margin-bottom: 20px;

  p {
    padding: 0;
    margin: 0;
    margin-right: 5px;
  }

  a {
    button {
      all: unset;
      text-align: center;
      margin: 0;
      font-size: 1.8rem;
      border-radius: 5px;
      padding: 3px 9px;
      border: 1px solid #777777;
      background-color: var(--link-hover);

      color: white;

      &:hover {
        color: #eee;
      }

      &:hover {
        background-color: #1e57a8;
      }

      &.active {
        background-color: #143a70;
        color: #eee;
      }
    }
  }
`

const getActiveCat = gatsLoc => {
  const { search } = gatsLoc

  if (search) {
    return _.get(queryString.parse(search), ['cat'], null)
  }

  return null
}

export default function SimpleFilter({ cats, location, includeCount = false }) {
  const catNames = _.isArray(cats) ? cats : Object.keys(cats)
  const sortedList = catNames.sort()

  const activeCat = getActiveCat(location)
  let fullCount = ''

  if (includeCount) {
    fullCount = ` (${catNames.reduce((prev, next) => (prev += cats[next]), 0)})`
  }

  return (
    <SimpleFilterStyles>
      <p>Filters:</p>
      <Link replace to={location.pathname} className="light no-hover">
        <button type="button" className={!activeCat ? 'active' : ''}>
          All{fullCount}
        </button>
      </Link>
      {sortedList.map(cat => {
        const catCount = includeCount ? ` (${cats[cat]})` : ''

        return (
          <Link
            replace
            to={`?cat=${cat}`}
            className="light no-hover"
            key={`cat-link-${cat}`}
          >
            <button
              type="button"
              key={`find-a-cat-${cat}`}
              className={`${activeCat === cat ? 'active' : ''}`}
            >
              {cat}
              {catCount}
            </button>
          </Link>
        )
      })}
    </SimpleFilterStyles>
  )
}
