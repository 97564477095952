import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const ChampListStyles = styled.div`
  overflow: scroll;
  font-size: 80%;
  text-align: center;

  table {
    margin: 0 auto;
    max-width: 1100px;
    border-collapse: collapse;
  }

  table {
    border: 1px solid black;

    thead tr th {
      padding: 10px;

      &:hover {
      }
    }

    tbody tr td {
      padding: 0 5px;

      :nth-child(5) {
        padding: 0;
      }
    }

    td,
    th {
      border: 1px solid #ddd;
    }
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  tbody {
    tr:hover {
      background-color: #ddd;
    }
  }

  table {
    thead {
      padding: 0;
      margin: 0;
      background-color: var(--link-hover);
      color: white;
    }
  }

  .group-info {
    /* text-align: left; */
    padding: 0;
    margin: 0;
    list-style: none;
  }
`

const ChampItem = ({ group }) => (
  <tr id={group.title.split(' ').join('-')}>
    <td>{group.year}</td>
    <td>{group.title}</td>
    <td>{group.category}</td>
    <td>
      <ul className="group-info">
        <li className="chapter">Chapter: {group.chapter}</li>
        <br />
        {group.groupInfo}
      </ul>
    </td>
    <td>
      <Img fixed={group.image.asset.fixed} alt={group.image.alt} />
    </td>
  </tr>
)

export default function DesktopChamps({ groups }) {
  return (
    <ChampListStyles>
      <table>
        <thead>
          <tr>
            <th>Year</th>
            <th>Group Name</th>
            <th>Category</th>
            <th>Group Info</th>
            <th>Image</th>
          </tr>
        </thead>
        <tbody>
          {groups.map(group => (
            <ChampItem key={group.id} group={group} />
          ))}
        </tbody>
      </table>
    </ChampListStyles>
  )
}
