import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import DesktopChamps from '../../components/DistrictChamps/DesktopView'
import MobileChamps from '../../components/DistrictChamps/MobileView'
import { getAllCats, createGroupInfo } from '../../utils/handleGroupInfo'
import SimpleFilter from '../../components/simpleFilter'

import { filterList } from '../../utils/utils'
import PageNavigationWrapper from '../../components/pagination/navWrapper'

const ChampContentStyles = styled.div`
  .title {
    margin-bottom: 30px;
  }

  .table-title {
    margin-bottom: 15px;
    text-align: center;
  }

  .mobile-version {
    display: block;
  }

  .desktop-version {
    display: none;
    margin: 0 auto;
  }

  .hidden {
    display: none !important;
  }

  .no-results {
    margin-top: 80px;
    text-align: center;

    h3 {
      margin-bottom: 20px;
    }
  }

  @media all and (min-width: 1136px) {
    .mobile-version {
      display: none;
    }

    .desktop-version {
      display: block;
    }
  }
`

const getYearRange = list => {
  const years = [...new Set(list.map(item => item.year))]

  return {
    min: Math.min(...years),
    max: Math.max(...years),
  }
}

export default function DistrictChamps({ data, location }) {
  const champs = data.champs.nodes
  const { pageInfo } = data.champs
  const allCats = getAllCats(champs)
  createGroupInfo(champs)

  const defaultTitle = 'All Champions'

  const { list, query } = filterList({
    list: champs,
    location,
    filterQueryName: 'cat',
    propToCheck: 'realCat',
    defaultTitle,
  })

  const yearRange = getYearRange(champs)

  const showCat = query === defaultTitle || query === 'Quartet Champions'

  return (
    <ChampContentStyles className="content-width">
      <h1 className="title">Pioneer Champions</h1>
      <SimpleFilter cats={allCats} location={location} />
      <h1 className="table-title">{query}</h1>
      <PageNavigationWrapper
        location={location}
        pageInfo={pageInfo}
        basePath="/history/district-champions"
      />
      <div className={`no-results ${list.length === 0 ? '' : 'hidden'}`}>
        <h3>
          No results for years {yearRange.min} to {yearRange.max}
        </h3>
        <h3>Go to a previous page to view more {query}</h3>
      </div>
      <div className={`desktop-version ${list.length === 0 ? 'hidden' : ''}`}>
        <DesktopChamps groups={list} />
      </div>
      <div className={`mobile-version ${list.length === 0 ? 'hidden' : ''}`}>
        <MobileChamps groups={list} showCat={showCat} />
      </div>
    </ChampContentStyles>
  )
}

export const ChampQuery = graphql`
  query ChampQuery($skip: Int!, $limit: Int!) {
    champs: allSanityDistrictChamps(
      sort: { fields: year, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      pageInfo {
        currentPage
        hasNextPage
        pageCount
        hasPreviousPage
      }
      nodes {
        category
        chapter
        title
        year
        tenor
        bass
        bari
        lead
        director
        id
        image {
          alt
          asset {
            fixed(width: 300) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 500) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`
