import React from 'react'

const mergeList = [
  'Mixed Quartet Champions',
  "Men's Quartet Champions",
  "Women's Quartet Champions",
]

export const getAllCats = champsList =>
  champsList.reduce((arr, item) => {
    let cat = item.category

    if (mergeList.includes(cat)) {
      const name = 'Quartet Champions'
      item.realCat = name
      cat = name
    } else {
      item.realCat = cat
    }

    if (!arr.includes(cat)) {
      arr.push(cat)
    }
    return arr
  }, [])

export const createGroupInfo = groupList =>
  groupList.map(group => {
    let groupInfo
    if (group.director) {
      groupInfo = (
        <>
          <li>Director: {group.director}</li>
        </>
      )
    } else {
      groupInfo = (
        <>
          <li>Tenor: {group.tenor}</li>
          <li>Lead: {group.lead}</li>
          <li>Bari: {group.bari}</li>
          <li>Bass: {group.bass}</li>
        </>
      )
    }

    group.groupInfo = groupInfo

    return group
  })
