import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

const YearStyles = styled.div`
  text-align: center;

  .year {
    font-size: 5rem;
    text-decoration: underline;
  }
`

const GroupStyles = styled.div`
  margin: 20px 0;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;

    .chapter {
      margin-bottom: 10px;
    }
  }

  .top-info,
  .bottom-info {
    margin-bottom: 20px;
  }

  .image-container {
    max-width: 600px;
    margin: 10px auto;
  }
`

const arrangeInYears = groups =>
  groups.reduce((obj, group) => {
    const { year } = group
    if (!obj[year]) {
      obj[year] = []
    }
    obj[year].push(group)

    return obj
  }, {})

const ChampionGroup = ({ group, showCat }) => {
  const catShow = showCat ? <h4>{group.category}</h4> : ''

  return (
    <GroupStyles className="item-wrapper" id={group.title.split(' ').join('-')}>
      <div className="top-info">
        <h2>{group.title}</h2>
        {catShow}
      </div>
      <div className="image-container">
        <Img fluid={group.image.asset.fluid} alt={group.image.alt} />
      </div>
      <div className="bottom-info">
        <ul>
          <li className="chapter">Chapter: {group.chapter}</li>
          {group.groupInfo}
        </ul>
      </div>
    </GroupStyles>
  )
}

export default function MobileChamps({ groups, showCat }) {
  const groupsArrangedInYears = arrangeInYears(groups)
  const years = Object.keys(groupsArrangedInYears).sort((a, b) => b - a)

  return years.map(year => (
    <YearStyles key={year}>
      <h2 className="year">{year}</h2>
      {groupsArrangedInYears[year].map(group => (
        <ChampionGroup
          key={`${group.id}-mobile`}
          group={group}
          showCat={showCat}
        />
      ))}
    </YearStyles>
  ))
}
